'use client';

import SubmitButton from '@/app/components/shared/SubmitButton';
import { useFormState } from 'react-dom';
import { FormStateWithMessage } from '@/utils/types';
import { Text, rem, Stack, TextInput } from '@mantine/core';
import { urlHelper } from '../../../utils';
import { useRouter } from 'next/navigation';
import Link from 'next/link';
import { signIn } from '../actions/sign-in';
import { clientSideSanctumInit } from '@/services/authentication/client-side-sanctum-init';

const initialState: FormStateWithMessage = {
  message: '',
};

const signInMethod =
  (callback: () => void) => async (prevState: FormStateWithMessage | undefined, formData: FormData) => {
    const email = formData.get('email');
    const password = formData.get('password');

    try {
      await clientSideSanctumInit();
      const res = await signIn(email as string, password as string);

      if (res?.ok) {
        callback();
      }

      if (res?.error) {
        return { message: 'Invalid user credentials' };
      }
    } catch (e) {
      return { message: 'Invalid user credentials' };
    }
  };

export default function LoginForm({ redirectLink }: { redirectLink: string | undefined }) {
  const router = useRouter();
  const callback = () => {
    router.push(redirectLink || urlHelper.assets());
  };

  const [formState, formAction] = useFormState(signInMethod(callback), initialState);

  return (
    <form action={formAction} className='flex flex-col p-4'>
      <Stack gap='xs'>
        <TextInput name='email' type='email' required={true} w={rem(400)} label='Email Address' withAsterisk={false} />
        <TextInput
          type='password'
          name='password'
          required={true}
          w={rem(400)}
          label='Password'
          withAsterisk={false}
          error={formState?.message}
        />
        <Link
          href={urlHelper.forgotPassword()}
          style={{
            alignSelf: 'flex-end',
          }}
        >
          <Text size='sm'>Forgot password?</Text>
        </Link>
        <SubmitButton>LOG IN</SubmitButton>
      </Stack>
    </form>
  );
}
