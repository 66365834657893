import { cloudApiClient } from '@/services/clientSideCloudApiService';
import { signIn as nextAuthSignIn } from 'next-auth/react';

export const signIn = async function (email: string, password: string) {
  await cloudApiClient.post('web-login', { email, password });
  const { data: user } = await cloudApiClient.get('user');
  return await nextAuthSignIn('credentials', {
    user: JSON.stringify(user),
    redirect: false,
  });
};
